import React, { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_WEB_URL;
    }, 500);
  }, []);
  require("./utils.css");
  return (
    <body>
      <div id="preloder">
        <div className="loader"></div>
      </div>
    </body>
  );
};

export default NotFound;
