/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import PagoService from "../../../services/Pago.service";
import ImagenesBorrar from "../productos/ImagenesBorrar";

const VerPago = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pago, setPago] = useState({});
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getDatosPago();
  }, []);

  const getDatosPago = async () => {
    setLoading(true);
    try {
      const data = await PagoService.getById(id);
      console.log(data)
      setPago(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
        
        </div>
      )}
    </>
  );
};

export default VerPago;
