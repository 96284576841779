import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";


const PrivateHeader = () => {
  const { logout, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
 
  const salir = () => {
    logout();
    window.location.reload(false);
    navigate("/tienda");
  };

  const displayUserOptions = () => {
    let element1;
    element1 = document.querySelector("#userOptions");

    let noti = document.querySelector("#notificationsUser");
    let setting = document.querySelector("#settingClick");
    if (
      element1.className ===
      "dropdown-menu dropdown-menu-right user-dd animated flipInY"
    ) {
      element1.className =
        "dropdown-menu dropdown-menu-right user-dd animated flipInY show";
      noti.className =
        "dropdown-menu dropdown-menu-left mailbox animated bounceInDown";
      setting.className = "dropdown-menu";
    } else {
      element1.className =
        "dropdown-menu dropdown-menu-right user-dd animated flipInY";
    }
  };

  const displaySettingOptions = () => {
    let element1;
    element1 = document.querySelector("#settingClick");

    let options;
    options = document.querySelector("#userOptions");

    let user = document.querySelector("#notificationsUser");

    if (element1.className === "dropdown-menu") {
      element1.className = "dropdown-menu show";
      options.className =
        "dropdown-menu dropdown-menu-right user-dd animated flipInY";
      user.className =
        "dropdown-menu dropdown-menu-left mailbox animated bounceInDown";
    } else {
      element1.className = "dropdown-menu";
    }
  };

  const displayNotifications = () => {
    navigate("/pagos")
  };

  const showSideResponsive = () => {
    let side = document.querySelector("#main-wrapper");
    let closeButton = document.querySelector("#iconoCerrar");

    if (side.className === "mini-sidebar") {
      side.className = "mini-sidebar show-sidebar";
      closeButton.className = "ti-close";
    } else {
      side.className = "mini-sidebar";
      closeButton.className = "ti-menu ti-close";
    }
  };

  return (
    <header className="topbar" data-navbarbg="skin6">
      <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <div className="navbar-header" data-logobg="skin6">
          {/* This is for the sidebar toggle which is visible on mobile only */}
          <a
            className="nav-toggler waves-effect waves-light d-block d-md-none"
            href="#/"
            onClick={() => showSideResponsive()}
          >
            <i id="iconoCerrar" className="ti-menu ti-close" />
          </a>
          {/* ============================================================== */}
          {/* Logo */}
          {/* ============================================================== */}
          <div className="navbar-brand">
            {/* Logo icon */}
            <a href="/">
              <b className="logo-icon">
                <img
                 onClick={() => navigate(`dashboard`)}
                  src="logoT.png"
                  alt="homepage"
                  className="dark-logo"
                  style={{marginLeft:"-10%", height:"75px", width:"230px"}}
                />
              </b>
            </a>
          </div>
          {/* ============================================================== */}
          {/* End Logo */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* Toggle which is visible on mobile only */}
          {/* ============================================================== */}
          <a
            className="topbartoggler d-block d-md-none waves-effect waves-light collapsed"
            href="#/"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            // onClick={() => displayUserSettingResponsive()}
            aria-label="Toggle navigation"
          >
            <i className="ti-more" />
          </a>
        </div>
        {/* ============================================================== */}
        {/* End Logo */}
        {/* ============================================================== */}
        <div
          className="navbar-collapse collapse"
          id="navbarSupportedContentResponsive"
        >
          {/* ============================================================== */}
          {/* toggle and nav items */}
          {/* ============================================================== */}
          <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
            {/* Notification */}
            {/* End Notification */}
            {/* ============================================================== */}
            {/* create new */}
            {/* ============================================================== */}
          </ul>
          <ul className="navbar-nav float-right">
            {/* ============================================================== */}
            {/* Search */}
            {/* ============================================================== */}
            
            {/* ============================================================== */}
            {/* User profile and search */}
            {/* ============================================================== */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#/"
                data-toggle="dropdown"
              >
                <img
                  src="https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png"
                  alt="user"
                  className="rounded-circle"
                  width={40}
                />
                <span
                  className="ml-2 d-none d-lg-inline-block"
                  onClick={() => displayUserOptions()}
                >
                  <span>Hola,</span>{" "}
                  <span className="text-dark">{`${currentUser.nombre} ${currentUser.apellidoPaterno}`}</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down svg-icon"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </span>
              </a>
              <div
                id="userOptions"
                className="dropdown-menu dropdown-menu-right user-dd animated flipInY"
              >
                
                <a className="dropdown-item" onClick={() => salir()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-power svg-icon mr-2 ml-1"
                  >
                    <path d="M18.36 6.64a9 9 0 1 1-12.73 0" />
                    <line x1={12} y1={2} x2={12} y2={12} />
                  </svg>
                  Logout
                </a>
                {/* <div className="dropdown-divider" />
                <div className="pl-4 p-3">
                  <a href="#/" className="btn btn-sm btn-info">
                    View Profile
                  </a>
                </div> */}
              </div>
            </li>
            {/* ============================================================== */}
            {/* User profile and search */}
            {/* ============================================================== */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default PrivateHeader;
