/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Grid
} from "@mui/material";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";
import { Delete, Visibility } from "@material-ui/icons";

// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import PagoService from "../../../services/Pago.service";
import S3Service from "../../../services/S3.services";
import ProductoService from "../../../services/Producto.service";

const PagoDetalles = ({ id, data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pago, setPago] = useState(data);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    loadPago();
  }, []);
  
  const visibleCheck = (valor) => {
    loadPago();
    setVisible(valor);
    document.getElementById("root").style.filter = "blur(1px)";
    if (valor == false) {
      document.getElementById("root").style.filter = "blur(0px)";
    }
  };

  const loadPago = async () => {
    setLoading(true);
    try {
      const result = await PagoService.getById(id);

      result.productos.map((producto, index) => {
        ProductoService.getById(producto.id).then((data) => {
          S3Service.get(data.imagenes[0]).then((dataImagen) => {
            document.getElementById(`imagen${producto.id}`).src =
              dataImagen.result;
          });
        });
      });

      setPago(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const estado = (state) => {
    if (state == "EnProceso") {
      return (
        <>
          <Badge
            badgeContent={"EnProceso"}
            color="warning"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          ></Badge>
        </>
      );
    }
    if (state == "Terminado") {
      return (
        <>
          <Badge badgeContent={"Terminado"} color="success"></Badge>
        </>
      );
    }
    if (state == "Cancelado") {
      return (
        <>
          <Badge badgeContent={"Cancelado"} color="error"></Badge>
        </>
      );
    }
  };

  const terminarPago = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de terminar la venta?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Terminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {  
          pago.status="Terminado"        
          PagoService.update(pago)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const cancelarPago = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cancelar la venta?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {  
          pago.status="Cancelado"        
          PagoService.update(pago)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  
  const eliminarPago = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de eliminar el pago?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          PagoService.remove(id)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });

          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {loading && pago != undefined ? (
        <Preloader />
      ) : (
        <tr key={id}>
          <th scope="row">{id}</th>
          <td>{pago.usuario.nombre}</td>
          <td>{pago.usuario.email}</td>
          <td>{pago.usuario.direccion}</td>
          <td>{pago.precio}</td>
          <td>
            {" "}
            <IconButton aria-label="ver" onClick={() => visibleCheck(!visible)}>
              <Visibility />
            </IconButton>
          </td>
          <td>
            {"   "}
            {estado(pago.status)}
          </td>
          <td>
            {" "}
            <IconButton aria-label="delete" onClick={() => eliminarPago(id)}>
              <Delete />
            </IconButton>
          </td>
        </tr>
      )}

      <CModal scrollable visible={visible} onClose={() => visibleCheck(false)}>
        <CModalHeader>
          <CModalTitle>Detalles de la compra</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Cliente</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      {pago.usuario.nombre} {pago.usuario.apellido}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Telefono</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.usuario.numeroTelefono}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Correo</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.usuario.email}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Gastado</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.precio}</p>
                  </TableCell>
                </TableRow>
                {pago.productos &&
                  pago.productos.map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell id={`name${item.id}`}>
                          {" "}
                          {item.cantidad} x {item.nombre} - {item.talla}{" "}
                        </TableCell>
                        <TableCell>
                          <img
                            id={`imagen${item.id}`}
                            src="https://c.tenor.com/Tu0MCmJ4TJUAAAAC/load-loading.gif"
                            width={"90px"}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}

                <TableRow>
                  <TableCell>
                    <p>Direccion</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.usuario.direccion}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Estado</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.status}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Ciudad</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.usuario.ciudad}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>CP</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.usuario.cp}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Indicaciones</p>
                  </TableCell>
                  <TableCell>
                    <p>{pago.indicaciones}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                    <p>Venta:</p>
                    </Grid>
                    <Grid item xs={4}>
                    {estado(pago.usuario.state)}
                    </Grid>
                  </Grid>
                    
                  </TableCell>
                  <TableCell>
                    <p>
                      <Button
                        aria-label="delete"
                        color="success"
                        onClick={() => terminarPago(id)}
                      >
                        Terminada
                      </Button>
                      <Button
                        aria-label="delete"
                        color="error"
                        onClick={() => cancelarPago(id)}
                      >
                        Cancelar
                      </Button>
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </CModalBody>
      </CModal>
    </>
  );
};

export default PagoDetalles;
