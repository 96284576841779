import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useFecha from "../../../hooks/useDate";
import S3Service from "../../../services/S3.services";

const NoticiaItem = ({ data }) => {
  const fecha = useFecha(data.createdAt);
  const navegar = useNavigate();
  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    try {
      const imagenData = await S3Service.get(data.imagen);

      if (imagenData.result) {
        document.getElementById(
          `blogImagen${data.id}`
        ).style.backgroundImage = `url(${imagenData.result})`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="blog__item">
        <div
          className="blog__item__pic set-bg"
          id={`blogImagen${data.id}`}
          style={{
            backgroundImage: `url(https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif)`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="blog__item__text">
          <span>
            <img src="img/icon/calendar.png" alt="" /> {fecha}
          </span>
          <h5>{data.titulo}</h5>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => navegar(`/noticia/${data.id}`)}
          >
            Leer mas
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoticiaItem;
