import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoticiaService from "../../../services/Noticia.service";
import NoticiaItem from "./NoticiaItem";
import { Helmet } from "react-helmet";
import { TablePagination } from "@mui/material";
const NoticiasGrid = () => {
  const [noticias, setNoticias] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getNoticias();
  }, [page, limit]);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setNoticias(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title>De todo original outdoors - Noticias</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="De todo original outdoors" />
        <meta property="og:image" content={"/LogoOf.png"} />
      </Helmet>
      {/* Breadcrumb Section Begin */}
      <section
        className="breadcrumb-blog set-bg"
        data-setbg="https://imcp.org.mx/wp-content/uploads/2021/05/OAXACA.jpg"
        style={{
          backgroundImage: `url(https://imcp.org.mx/wp-content/uploads/2021/05/OAXACA.jpg)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Noticias</h2>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End */}
      {/* Blog Section Begin */}
      <section className="blog spad">
        <div className="container">
          <div className="row">
            {noticias.map((record) => {
              return <NoticiaItem data={record} />;
            })}
          </div>
          <div className="col-lg-12">
            <div className="product__pagination">
              {/* <a className="active" onClick={() => console.log("A")}>
                      1
                    </a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <span>...</span>
                    <a href="#">21</a> */}
              <TablePagination
                style={{ color: "var(--color-dark-variant)" }}
                component="div"
                classes="recent-orders"
                labelRowsPerPage="Items por página"
                //  rowsPerPageOptions={[]}
                count={total}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Blog Section End */}
    </>
  );
};

export default NoticiasGrid;
