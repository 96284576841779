/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.services";

const EditarCarrusel = () => {
  const { id } = useParams();
  const [carrusel, setCarrusel] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await CarruselService.getById(id);
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCarrusel = async () => {
    if (!carrusel.titulo) {
      toast.info("Error, tienes que colocar un titulo");
    } else if (!carrusel.subtitulo) {
      toast.info("Error, tienes que colocar un subtitulo");
    } else {
      setLoading(true);
      try {
        const datosCarrusel = carrusel;
        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          datosCarrusel.imagen = uploadPhoto.result.data;
        }
        await CarruselService.update(datosCarrusel);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/carrusel");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="col-12">
            <div className="card">
              <div className="card-body">                
                <div className="grid-structure">
                  <div
                    className="grid-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div style={{ display: "flex" }}>
                  <h4 className="card-title">Nuevo item carrusel</h4>
                </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Titulo
                        </label>
                        <input
                          onChange={(e) =>
                            setCarrusel({ ...carrusel, titulo: e.target.value })
                          }
                          value={carrusel.titulo}
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Subtitulo
                        </label>
                        <input
                          onChange={(e) =>
                            setCarrusel({
                              ...carrusel,
                              subtitulo: e.target.value,
                            })
                          }
                          value={carrusel.subtitulo}
                          className={`form-control`}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          URL
                        </label>
                        <input
                          onChange={(e) =>
                            setCarrusel({ ...carrusel, url: e.target.value })
                          }
                          value={carrusel.url}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "45px" }}>
                      <div className="col-lg-12">
                        <p>Imagen</p>
                        <DropZone
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>

                    {file ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {!file && carrusel.imagen ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={String(imagen)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* Botones */}
                    <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                      <div className="col-lg-12">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate("/carrusel")}
                          style={{ margin: "10px" }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => updateCarrusel()}
                          style={{ margin: "10px" }}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditarCarrusel;
