/* React */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { CirclePicker } from "react-color";

/* Servicios */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.services";

const CrearCarrusel = () => {
  const [carrusel, setCarrusel] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [titleColor, setTitleColor] = useState("black");
  const [subtitleColor, setSubtitleColor] = useState("black");
  const [urlColor, setUrlColor] = useState("black");
  const navigate = useNavigate();

  const background = "#fff";

  const handleChangeComplete = (color) => {};

  const saveCarrusel = async () => {
    if (!carrusel.titulo) {
      toast.info("Error, tienes que colocar un titulo");
    } else if (!carrusel.subtitulo) {
      toast.info("Error, tienes que colocar un subtitulo");
    } else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } else {
      setLoading(true);
      try {
        const datosCarrusel = carrusel;
        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          datosCarrusel.imagen = uploadPhoto.result.data;
        }
        await CarruselService.create(datosCarrusel);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/carrusel");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="grid-structure">
                  <div
                    className="grid-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h4 className="card-title">Nuevo item carrusel</h4>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                          <label
                            className="form-control-label"
                            htmlFor="inputDanger1"
                          >
                            Titulo
                          </label>
                        <input
                          onChange={(e) =>
                            setCarrusel({ ...carrusel, titulo: e.target.value })
                          }
                          value={carrusel.titulo}
                          className={`form-control`}
                          style={{ color: titleColor }}
                        />
                        <div style={{marginTop:"5%", marginLeft:"25%"}}>
                          <CirclePicker
                            colors={[  
                              "#8494a4",
                              '#e1e4e8',
                              '#d5a667',
                              '#77b527',
                              '#1da1f2',
                              "#e53637",
                              "#000000",
                              "#323232",
                              "#333",
                              "#999",                              
                              "#51647d",
                              "#20315f",
                            ]}
                            onChange={(targetColor) =>
                              {setTitleColor(targetColor.hex);
                                setCarrusel({
                                  ...carrusel,
                                  tituloColor: targetColor.hex,
                                })}
                            }
                          />
                        </div>
                          
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Subtitulo
                        </label>
                        <input
                          onChange={(e) =>
                            setCarrusel({
                              ...carrusel,
                              subtitulo: e.target.value,
                            })
                          }
                          value={carrusel.subtitulo}
                          className={`form-control`}
                          style={{ color: subtitleColor }}
                        />
                        <div style={{marginTop:"5%",marginLeft:"25%"}}>
                          <CirclePicker
                            colors={[  
                              "#8494a4",
                              '#e1e4e8',
                              '#d5a667',
                              '#77b527',
                              '#1da1f2',
                              "#e53637",
                              "#000000",
                              "#323232",
                              "#333",
                              "#999",                              
                              "#51647d",
                              "#20315f",
                            ]}
                            onChange={(targetColor) =>
                              {setSubtitleColor(targetColor.hex)
                                setCarrusel({
                                  ...carrusel,
                                  subtituloColor: targetColor.hex,
                                })
                              }
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          URL
                        </label>
                        <input
                          onChange={(e) =>
                            setCarrusel({ ...carrusel, url: e.target.value })
                          }
                          value={carrusel.url}
                          className={`form-control`}
                          style={{ color: urlColor }}
                        />
                        <div style={{marginTop:"2%",marginLeft:"20%"}}>
                          <CirclePicker
                            colors={[  
                              "#8494a4",
                              '#e1e4e8',
                              '#d5a667',
                              '#77b527',
                              '#1da1f2',
                              "#e53637",
                              "#000000",
                              "#323232",
                              "#333",
                              "#999",                              
                              "#51647d",
                              "#20315f",
                            ]}
                            onChange={(targetColor) =>
                              {setUrlColor(targetColor.hex)
                                setCarrusel({
                                  ...carrusel,
                                  urlColor: targetColor.hex,
                                })
                              }
                            }
                            width="900"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "45px" }}>
                      <div className="col-lg-12">
                        <p>Imagen</p>
                        <DropZone
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>

                    {file ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* Botones */}
                    <div
                      className="row"
                      style={{ marginTop: "20px", textAlign: "center" }}
                    >
                      <div className="col-lg-12">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate("/carrusel")}
                          style={{ margin: "10px" }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => saveCarrusel()}
                          style={{ margin: "10px" }}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CrearCarrusel;
