import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

function preventDefault(event) {
  event.preventDefault();
}

export default function CardCantidad(props) {
  return (
    <React.Fragment>
        <Typography
          component="h2"
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
          color="text.secondary"
        >
          {props.mainText}
        </Typography>

        <Typography component="p" variant="h4" color="text.secondary">
          {props.total}
        </Typography>
        <Typography color="text.secondary" sx={{ flex: 1 }}>
          {props.secondaryText}
        </Typography>
        <div style={{ textAlign: "right" }}>
          <Link color="text.secondary" href={props.link}>
            {props.ver}
          </Link>
        </div>
    </React.Fragment>
  );
}
