const FullPageLoading = () => {
  require("./utils.css");
  return (
    <body>
      <div id="preloder">
        <div className="loader"></div>
      </div>
    </body>
  );
};

export default FullPageLoading;
