import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Transition,
  CSSTransition,
  SwitchTransition,
  TransitionGroup,
} from "react-transition-group";
import ProductoService from "../../../services/Producto.service";
import ItemTienda from "./ItemTienda";
const PreviewProductos = () => {
  require("./util.css");
  const navigate = useNavigate();
  const [active, setActive] = useState("todo");
  const [showBest, setShowBest] = useState(true);
  const [showCaballero, setShowCaballeros] = useState(false);
  const [showDama, setShowDama] = useState(false);
  /* States to storage products */
  const [caballero, setCaballero] = useState([]);
  const [dama, setDama] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const productosCaballero = await ProductoService.listByCategory(4,0,"Caballero");      
      const productosDama = await ProductoService.listByCategory(4, 0, "Dama");      
      const productoBestSellers = await ProductoService.listBestSeller(4);

      setBestSellers(productoBestSellers.data);
      setDama(productosDama.data);
      setCaballero(productosCaballero.data);
    } catch (error) {
      console.log(error);
    }
  };

  const activeCaballero = () => {
    setActive("caballero");
    setShowBest(false);
    setShowCaballeros(true);
    setShowDama(false);
  };

  const activeBest = () => {
    setActive("best");
    setShowBest(true);
    setShowCaballeros(false);
    setShowDama(false);
  };

  const activeDama = () => {
    setActive("dama");
    setShowBest(false);
    setShowCaballeros(false);
    setShowDama(true);
  };

  return (
    <section className="product spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ul className="filter__controls">
              <li
                className={`${active === "best" && "active"}`}
                data-filter="*"
                onClick={() => activeBest()}
              >
                Los más vendidos
              </li>
              <li
                className={`${active === "caballero" && "active"}`}
                onClick={() => activeCaballero()}
              >
                Caballero
              </li>
              <li
                className={`${active === "dama" && "active"}`}
                onClick={() => activeDama()}
              >
                Dama
              </li>
            </ul>
          </div>
        </div>

        <CSSTransition
          in={showBest}
          timeout={300}
          classNames="example"
          unmountOnExit
        >
          <>
            {showBest ? (
              <div className="row product__filter">
                <>
                  {bestSellers.map((record, index) => {
                    return <ItemTienda data={record} />;
                  })}
                </>
              </div>
            ) : null}
          </>
        </CSSTransition>

        <CSSTransition
          in={showCaballero}
          timeout={300}
          classNames="example"
          unmountOnExit
        >
          <>
            {showCaballero ? (
              <div className="row product__filter">
                <>
                  {caballero.map((record, index) => {
                    return <ItemTienda data={record} />;
                  })}
                </>
              </div>
            ) : null}
          </>
        </CSSTransition>

        <CSSTransition
          in={showDama}
          timeout={300}
          classNames="example"
          unmountOnExit
        >
          <>
            {showDama ? (
              <div className="row product__filter">
                <>
                  {dama.map((record, index) => {
                    return <ItemTienda data={record} />;
                  })}
                </>
              </div>
            ) : null}
          </>
        </CSSTransition>
      </div>
      <center>
        <div className="blog__item">
          <div className="blog__item__text">
            <a onClick={() => navigate("/tienda")}>VER MÁS</a>
          </div>
        </div>
      </center>
    </section>
  );
};

export default PreviewProductos;
