import React from "react";
import { useNavigate } from "react-router-dom";

const PublicFooter = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer" style={{position: "relative !important"}}>
      <div className="container" style={{bottom:0,}}>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="footer__about">
              <div className="footer__logo">
                <a href="/">
                  <img src="/logoT.png" alt="aqui va" style={{height:"80px", width:"250px", marginTop:"-10px"}} />
                </a>
              </div>
              <p>
                Estamos dedicados a los más altos estándares de servicio al
                cliente.
              </p>
              <a href="\#">
                <img src="img/payment.png" alt="" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-1 col-md-3 col-sm-6">
            <div className="footer__widget">
              <h6>Tienda</h6>
              <ul>
                <li>
                  <a
                  href="#"
                    style={{ cursor: "pointer", color: "#b7b7b7" }}
                    onClick={() => navigate(`/tienda`)}
                  >
                    Tienda
                  </a>
                </li>
                <li>
                  <a
                  href="#"
                    style={{ cursor: "pointer", color: "#b7b7b7" }}
                    onClick={() => navigate(`/tienda/hombre`)}
                  >
                    Colección caballeros
                  </a>
                </li>
                <li>
                  <a
                  href="#"
                    style={{ cursor: "pointer", color: "#b7b7b7" }}
                    onClick={() => navigate(`/tienda/mujeres`)}
                  >
                    Colección damas
                  </a>
                </li>
                <li>                  
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="footer__widget">
              <h6>Acerca de</h6>
              <ul>
                <li>
                  <a
                    style={{ cursor: "pointer", color: "#b7b7b7" }}
                    onClick={() => navigate(`/contacto`)}
                  >
                    Contacto
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer", color: "#b7b7b7" }}
                    onClick={() => navigate(`/noticias`)}
                  >
                    Noticias
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer", color: "#b7b7b7" }}
                    onClick={() => navigate(`/`)}
                  >
                    Inicio
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="footer__copyright__text">
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              <p>
                 © 2022 {" "}
                 <a href="http://www.karimnot.com/" target="_blank" style={{display: "inline-block"}}>
                  KARIMNOT INC
                </a>{" "}/ Todos los derechos reservados <br/>                 
                <a onClick={() => navigate("/aviso")} href="">
                  Aviso de privacidad
                </a>
              </p>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PublicFooter;
