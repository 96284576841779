/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button,TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import MensajeDetalles from "./MensajeDetalles";

/* Servicios */
import MensajeService from "../../../services/Mensaje.service";

const TablaMensaje = () => {
  const [mensajes, setMensajes] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getMensajes();
  }, [page, limit]);

  const getMensajes = async () => {
    setLoading(true);
    try {
      const data = await MensajeService.list(limit, page * limit);
      setMensajes(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div style={{ display: "flex" }}>
                  <h4
                    className="card-title"
                    style={{ marginRight: "20px", marginTop: "6px" }}
                  >
                    Mensajes
                  </h4>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Email</th>
                      <th scope="col">Ver</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mensajes.map((record, index) => {
                      return (
                        <MensajeDetalles id={record.id} data={record} />
                       );                      
                    })}
                  </tbody>
                </table>
              </div>
              <TablePagination
                style={{ color: "var(--color-dark-variant)" }}
                component="div"
                classes="recent-orders"
                labelRowsPerPage="Items por página"
                count={total}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaMensaje;
