import React, { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import Carousel from "nuka-carousel";
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.services";
import FullPageLoading from "../../utils/FullPageLoading";
const Carrusel = () => {
  const [carrusels, setCarrusels] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await CarruselService.getAll();
      setCarrusels(data);
      for (const record of data) {
        const imagen = await S3Service.get(record.imagen);

        setImagenes((imagenes) => [...imagenes, imagen.result]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const buton = (url,urlColor) => {
    return (
      <a
        href={url}
        className="primary-btn"
        style={{ display: "contents",color: urlColor }}
      >
        IR <span className="arrow_right" />
      </a>
    );
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <section className="hero">
          <div
            className="hero__slider owl-carousel "
            style={{ display: "contents" }}
          >
            <Carousel
              wrapAround={true}
              slidesToShow={1}
              defaultControlsConfig={{
                nextButtonText: ">",
                prevButtonText: "<",
              }}
            >
              {carrusels.map((record, index) => {
                return (
                  <div
                    id={`imagen${record.id}`}
                    className="hero__items set-bg "
                    style={{
                      backgroundImage: `url(${imagenes[index]})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-5 col-lg-7 col-md-8">
                          <div className="hero__text">
                            <br />
                            <br />
                            <br />
                            <div style={{ background: "rgb(0 0 0 / 15%)", padding:"20px", width:"auto", overflow:"auto"}}>
                              <h2
                                style={{
                                  display: "contents",
                                  background: "rgb(0 0 0 / 34%)",
                                  color: record.tituloColor
                                }}
                              >
                                {record.titulo}
                              </h2>
                              <br />
                              <br />
                              <h6 style={{ display: "contents", color: record.subtituloColor}}>
                                {record.subtitulo}
                              </h6>
                              <br />
                              <br />
                              {record.url ? buton(record.url,record.urlColor) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </section>
      )}
    </>
  );
};

export default Carrusel;
