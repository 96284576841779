/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

/* Servicios */
import CategoriasInicioService from "../../../services/CategoriasInicio.service";
import S3Service from "../../../services/S3.services";

const TablaCategoriaInicio = () => {
  const [categoria, setCategoria] = useState({});
  const [categoriaEdit, setCategoriaEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const navigate = useNavigate();
  const [file, setFile] = useState(undefined);
  const [openEdit, setOpenEdit] = useState(false);
  const [imagen, setImagen] = useState("");
  const [hombres,setHombres]=useState({});
  const [mujeres,setMujeres]=useState({});
  const [outdoors,setOutdoors]=useState({});

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    getCategorias();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const caballeros=await CategoriasInicioService.getById(1);
      
      const imagenCaballeros = await S3Service.get(caballeros.imagen);
      caballeros.url=imagenCaballeros.result;
      setHombres(caballeros);

      const damas=await CategoriasInicioService.getById(2);      
      const imagenDamas = await S3Service.get(damas.imagen);
      damas.url=imagenDamas.result;
      setMujeres(damas);

      const out=await CategoriasInicioService.getById(3);      
      const imagenOut = await S3Service.get(out.imagen);
      out.url=imagenOut.result;
      setOutdoors(out);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCategoria = async () => {
    setLoading(true);
    try {
      const datosCategoria = categoriaEdit;
      if (file) {
        await S3Service.delete(datosCategoria.imagen);
        const uploadPhoto = await S3Service.upload(file);
        datosCategoria.imagen = uploadPhoto.result.data; 
      }  
      const newImagen={
        id:datosCategoria.id,
        titulo:datosCategoria.titulo,
        imagen:datosCategoria.imagen,
      }
      const up=await CategoriasInicioService.update(newImagen);
      setFile(undefined);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      handleCloseEdit();
      getCategorias();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex" }}>
                    <h4
                      className="card-title"
                      style={{ marginRight: "20px", marginTop: "6px" }}
                    >
                      Imágenes categoría inicio
                    </h4>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Sección</th>
                        <th scope="col">Imágenes</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                        
                    <tr>
                          <td scope="row">{hombres.id}</td>
                            <td>{hombres.titulo}</td>
                            <td>
                              {hombres.imagen ? (
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        src={hombres.url}
                                        style={{
                                          height: 300,
                                          objectFit: "contain",
                                          width: "-webkit-fill-available",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </td>
                            <td>
                              {" "}
                              <span
                                className="material-icons-sharp"
                                onClick={() => {
                                  setCategoriaEdit(hombres);
                                  handleOpenEdit();
                                }}
                              >
                                {" "}
                                edit{" "}
                              </span>
                            </td>
                            </tr>
                    <tr>
                          <td scope="row">{mujeres.id}</td>
                            <td>{mujeres.titulo}</td>
                            <td>
                              {mujeres.imagen ? (
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        src={mujeres.url}
                                        style={{
                                          height: 300,
                                          objectFit: "contain",
                                          width: "-webkit-fill-available",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </td>
                            <td>
                              {" "}
                              <span
                                className="material-icons-sharp"
                                onClick={() => {
                                  setCategoriaEdit(mujeres);
                                  handleOpenEdit();
                                }}
                              >
                                {" "}
                                edit{" "}
                              </span>
                            </td>
                            </tr>
                            <tr>
                          <td scope="row">{outdoors.id}</td>
                            <td>{outdoors.titulo}</td>
                            <td>
                              {hombres.imagen ? (
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        src={outdoors.url}
                                        style={{
                                          height: 300,
                                          objectFit: "contain",
                                          width: "-webkit-fill-available",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </td>
                            <td>
                              {" "}
                              <span
                                className="material-icons-sharp"
                                onClick={() => {
                                  setCategoriaEdit(outdoors);
                                  handleOpenEdit();
                                }}
                              >
                                {" "}
                                edit{" "}
                              </span>
                            </td>
                            </tr>                     
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  classes="recent-orders"
                  labelRowsPerPage="Items por página"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
            {/** EDITAR */}
            <Modal
              open={openEdit}
              onClose={handleCloseEdit}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Editar imágen {categoriaEdit.titulo}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {file ? (
                    <div className="row">
                      <div className="col-lg-12">
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={URL.createObjectURL(file)}
                            style={{
                              height: 300,
                              objectFit: "contain",
                              width: "-webkit-fill-available",
                            }}
                          />
                          <Typography
                            variant="body1"
                            component="span"
                            style={{
                              width: "-webkit-fill-available",
                            }}
                          >
                            {file.name}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row" style={{ marginTop: "10px" }}>                  
                    <div className="col-lg-12">
                      <DropZone
                        saveFile={async (file) => {
                          setFile(file);
                          var reader = new FileReader();
                          var url = reader.readAsDataURL(file);
                        }}
                      />
                    </div>
                  </div>                  
                </Typography>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-lg-6">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCloseEdit}
                    >
                      Cancelar
                    </Button>
                  </div>
                  <div className="col-lg-6">
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => updateCategoria()}
                    >
                      Guardar
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaCategoriaInicio;
