import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoticiaService from "../../../services/Noticia.service";
import NoticiaCard from "./NoticiaCard";
import FullPageLoading from "../../utils/FullPageLoading";

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getNoticias();
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(3, 0);
      setNoticias(data.data);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <section className="latest spad" style={{ marginTop: "-174px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <span>ÚLTIMAS NOTICIAS</span>
                  <h2>Noticias para nuestra comunidad</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {noticias.map((record) => {
                return <NoticiaCard data={record} />;
              })}
            </div>
          </div>
          <center>
            <div className="blog__item">
              <div className="blog__item__text">
                <a onClick={() => navigate("/noticias")}>VER MÁS</a>
              </div>
            </div>
          </center>
        </section>
      )}
    </>
  );
};

export default Noticias;
