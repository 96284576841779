import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PrivateHeader from "./PrivateHeader";
import PrivateSide from "./PrivateSide";
import { useNavigate } from "react-router-dom";

const PrivateLayout = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    require("./css/c3.min.css");
    require("./css/chartist.min.css");
    require("./css/jquery-jvectormap-2.0.2.css");
    require("./css/style.css");
  }, []);

  return (
    <>
      <div className="privateLayout">
        <div
          id="main-wrapper"
          data-theme="light"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype={`${width > 1170 ? "full" : "mini-sidebar"}`}
          class={`${width > 1170 ? "" : "mini-sidebar"}`}
          // data-sidebartype="mini-sidebar"
          data-sidebar-position="relative"
          data-header-position="fixed"
          data-boxed-layout="full"
        >
          <PrivateHeader />

          <PrivateSide />

          <div className="page-wrapper" style={{ display: "block",  }}>
            <Outlet />
            <footer
              className="footer text-center text-muted"
              
              style={{ backgroundColor: "#f9fbfd", paddingLeft:"25%" }}
              
            >
              <div className="text-center" style={{ textAlign:"center"}}>
                <p>
                 © 2022 {" "}
                 <a href="http://www.karimnot.com/" target="_blank" style={{display: "inline-block"}}>
                  KARIMNOT INC
                </a>{" "}/ Todos los derechos reservados <br/>                 
                <a onClick={() => navigate("/avisoprivate")} href="">
                  Aviso de privacidad
                </a>
                </p>
              </div>
              
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateLayout;
