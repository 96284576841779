import httpClient from "./HttpClient";
const prefix = "/categoriasInicio";

export default class CategoriasInicioService {
  static async update(categoriasInicio) {
    return (await httpClient.put(`${prefix}/${categoriasInicio.id}`, categoriasInicio)).data;
  }
 
  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

}
