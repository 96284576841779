import { useCallback, useState } from "react";
import { Box, Chip, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import CategoriaService from "../../../services/Categoria.service";
import { toast } from "react-toastify";

export function ChipListManual({ label, onChange, list, text, setText }) {
  const [categorias, setCategorias] = useState([]);
  const [chips, setChips] = useState(list ? list : []);
  useEffect(() => {
    onChange(chips.join(", "));
    getCategorias();
  }, [chips]);

  const getCategorias = async () => {
    
    try {
      const data = await CategoriaService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const handleDelete = (index) => {
    chips.splice(index, 1);
    setChips([...chips]);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleKey = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      chips.push(text);
      setChips([...chips]);
      setText("");
      event.preventDefault();
    }
  };

  return (
    <div style={{ width: "100%", marginBottom: 15, marginTop: 15 }}>
      <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
        {label}
      </Typography>
      <Box sx={{ flexDirection: "row" }}>
        {chips.map((label, index) => {
          return (
            <Chip
              label={label}
              key={index}
              style={{ margin: 10, backgroundColor: "white" }}
              onClick={() => handleDelete(index)}
              onDelete={() => handleDelete(index)}
            />
          );
        })}
        <select
          className="form-control"
          id="inlineFormCustomSelect"
          name="categoria"
          onChange={(e) => {
            chips.push(e.target.value);
            setChips([...chips]);
            setText("");
          }}
        >
          <option disabled="disabled" selected="selected">
            Selecciona una
          </option>
          {categorias.map((record, index) => {
                        return (
                          <option value={record.tipo}>{record.tipo}</option>
                        );})}
          
          <option value="Dama">Dama</option>
          <option value="Unisex">Unisex</option>
        </select>
      </Box>
    </div>
  );
}
