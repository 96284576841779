import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.services";

const ItemTienda = ({ data }) => {
  let precioFinal = data.precio;
  const navigate = useNavigate();
  if (data.descuento > 0) {
    const descuentoProps = data.descuento;
    const precioOriginal = data.precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }

  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    try {
      const imagenData = await S3Service.get(data.imagenes[0]);
      
      if (imagenData.result) {
        document.getElementById(
          `blogImagen${data.id}`
        ).style.backgroundImage = `url(${imagenData.result})`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-md-6 col-sm-6 mix new-arrivals">
      <a onClick={() => navigate(`/producto/${data.id}`)}>
        <div className="product__item">
          <div
            id={`blogImagen${data.id}`}
            className="product__item__pic set-bg"
            style={{
              backgroundImage: `url(https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div className="product__item__text">
            <h6>{data.nombre}</h6>
            <h5>${Math.round(precioFinal)}.00</h5>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ItemTienda;
