import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

import MensajeService from "../../../services/Mensaje.service";
import ContactanosService from "../../../services/Contactanos.service";
const ContactoPublic = () => {
  const [contactanos, setContactanos] = useState({ undefined });
  const [mensaje, setMensaje] = useState({ undefined });
  const [valido, setValido] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContactanos();
    window.scrollTo(0, 0);
  }, []);

  const getContactanos = async () => {
    setLoading(true);
    try {
      const cont = await ContactanosService.getAll();
      setContactanos(cont[0]);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  const enviarMensaje = async () => {
    if (!mensaje.Nombre) {
      toast.info("Error, tienes que colocar tu nombre");
    } else if (!mensaje.Email) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar una mensaje");
    } else {
      setLoading(true);
      try {
        const datosMensaje = mensaje;
        datosMensaje.Status = "SinAbrir";
        await MensajeService.create(datosMensaje);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          Email: "",
          MensajeCuerpo: "",
        };
        setMensaje(limpiar);
        setValido(true);
        toast.info("Gracias, mensaje enviado");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>De todo original outdoors - Contacto</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="De todo original outdoors" />
        <meta property="og:image" content={"/LogoOf.png"} />
      </Helmet>
      {/* Map Begin */}
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d111551.9926412813!2d-90.27317134641879!3d38.606612219170856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1597926938024!5m2!1sen!2sbd"
          height={500}
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex={0}
        />
      </div>
      {/* Map End */}
      {/* Contact Section Begin */}
      <section className="contact spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="contact__text">
                <div className="section-title">
                  <span>Informacion</span>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="contact__text">
                <div className="section-title">
                  <h2>Contactanos</h2>
                  <p
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: contactanos.Cuerpo,
                    }}
                  ></p>
                </div>
                <ul>
                  <li>
                    <h4>{contactanos.Numero}</h4>
                    <p>
                    {contactanos.Direccion} <br />
                    {contactanos.Horario}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="contact__form">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      onChange={(e) =>
                        setMensaje({ ...mensaje, Nombre: e.target.value })
                      }
                      placeholder="Nombre"
                      value={mensaje.Nombre}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      placeholder="Email"
                      onChange={(e) =>
                        setMensaje({ ...mensaje, Email: e.target.value })
                      }
                      value={mensaje.Email}
                    />
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="Mensaje"
                      defaultValue={""}
                      onChange={(e) =>
                        setMensaje({
                          ...mensaje,
                          MensajeCuerpo: e.target.value,
                        })
                      }
                      value={mensaje.MensajeCuerpo}
                    />
                    <div className="row">
                      <ReCAPTCHA
                        sitekey={"6LfAC18jAAAAAMzQTRN2K7shO2tZeskMJWv_Q7Rd"}
                        onChange={onChange}
                      />
                      <button
                        className="site-btn"
                        style={{
                          marginLeft: "3%",
                          backgroundColor: valido ? "#f3f3f3" : "black",
                        }}
                        disabled={valido}
                        onClick={() => enviarMensaje()}
                      >
                        Enviar mensaje
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>          
        </div>
      </section>
      {/* Contact Section End */}
    </>
  );
};

export default ContactoPublic;
