/* Dependencies */
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FullPageLoading from "./components/utils/FullPageLoading";

import PublicLayout from "./components/public/layout/PublicLayout";
import ShopHome from "./components/public/shop";
import Aviso from "./components/public/Aviso";
import AvisoPrivate from "./components/private/AvisoPrivate";
import Login from "./components/public/login/Login";
import PrivateLayout from "./components/private/layout/PrivateLayout";
import TablaProductos from "./components/private/productos/TablaProductos";
import Preloader from "./components/utils/Preloader";
import CrearProducto from "./components/private/productos/CrearProducto";
import DetallesProducto from "./components/private/producto/DetallesProducto";
import TiendaCompleta from "./components/public/tiendaCompleta/TiendaCompleta";
import ContactoPublic from "./components/public/contacto/ContactoPublic";
import CarritoDetalles from "./components/public/carrito/CarritoDetalles";
import Checkout from "./components/public/checkout/Checkout";
import EditarProducto from "./components/private/productos/EditarProducto";
import EliminarProducto from "./components/private/productos/EliminarProducto";
import TablaNoticias from "./components/private/noticias/TablaNoticias";
import CrearNoticia from "./components/private/noticias/CrearNoticia";
import NoticiasGrid from "./components/public/noticias/NoticiasGrid";
import NoticiaDetalles from "./components/public/noticias/NoticiaDetalles";
import EditarNoticia from "./components/private/noticias/EditarNoticia";
import EliminarNoticia from "./components/private/noticias/EliminarNoticia";
import TablaPagos from "./components/private/pagos/TablaPagos";
import VerPago from "./components/private/pagos/VerPago";
import Corte from "./components/private/pagos/Corte";
import TablaUsuarios from "./components/private/usuarios/TablaUsuario";
import NuevoUsuario from "./components/private/usuarios/NuevoUsuario";
import EditarUsuario from "./components/private/usuarios/EditarUsuario";
import Micuenta from "./components/private/usuarios/MiCuenta";
import EliminarUsuario from "./components/private/usuarios/EliminarUsuario";
import CrearCarrusel from "./components/private/carrusel/CrearCarrusel";
import TablaCarrusel from "./components/private/carrusel/TablaCarrusel";
import EditarCarrusel from "./components/private/carrusel/EditarCarrusel";
import EliminarCarrusel from "./components/private/carrusel/EliminarCarrusel";
import NotFound from "./components/utils/NotFound";
import Dashboard from "./components/private/dashboard/dashboard";
import TablaCategoria from "./components/private/categoria/TablaCategoria";
import TablaCategoriaInicio from "./components/private/categoriasInicio/TablaCategoriaInicio";
import TablaAviso from "./components/private/aviso/TablaAviso";
import TablaMensaje from "./components/private/mensaje/TablaMensaje";
import TablaContactanos from "./components/private/contactanos/TablaContactanos";

function App() {
  const { currentUser, checkUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.removeItem("react-use-cart");
    setTimeout(() => {
      setLoading(true);
      checkUser();
      setLoading(false);
    }, 500);
  });

  const publicRoutes = (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<ShopHome />} />
        <Route path="producto/:id" element={<DetallesProducto />} />
        <Route path="tienda" element={<TiendaCompleta />} />
        <Route path="tienda/:filtroI" element={<TiendaCompleta />} />
        <Route path="contacto" element={<ContactoPublic />} />
        <Route path="carrito" element={<CarritoDetalles />} />
        <Route path="pagar" element={<Checkout />} />
        <Route path="noticias" element={<NoticiasGrid />} />
        <Route path="noticia/:id" element={<NoticiaDetalles />} />
        <Route path="aviso" element={<Aviso />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );

  const adminRoutes = (
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index element={<Dashboard/>} />
        <Route path="producto" element={<TablaProductos />} />
        <Route path="producto/nuevo" element={<CrearProducto />} />
        <Route path="producto/editar/:id" element={<EditarProducto />} />
        <Route path="producto/eliminar/:id" element={<EliminarProducto />} />
        <Route path="noticias" element={<TablaNoticias />} />
        <Route path="noticias/nuevo" element={<CrearNoticia />} />
        <Route path="noticias/editar/:id" element={<EditarNoticia />} />
        <Route path="noticias/eliminar/:id" element={<EliminarNoticia />} />
        <Route path="pagos" element={<TablaPagos />} />
        <Route path="pagos/detalles/:id" element={<VerPago />} />
        <Route path="pagos/corte" element={<Corte />} />
        <Route path="usuarios" element={<TablaUsuarios />} />
        <Route path="usuarios/nuevo" element={<NuevoUsuario />} />
        <Route path="usuarios/editar/:id" element={<EditarUsuario />} />
        <Route path="usuarios/eliminar/:id" element={<EliminarUsuario />} />
        <Route path="carrusel" element={<TablaCarrusel />} />
        <Route path="carrusel/nuevo" element={<CrearCarrusel />} />
        <Route path="carrusel/editar/:id" element={<EditarCarrusel />} />
        <Route path="carrusel/eliminar/:id" element={<EliminarCarrusel />} />
        <Route path="micuenta" element={<Micuenta />} />
        <Route path="avisoprivate" element={<AvisoPrivate />} />
        <Route path="categoria" element={<TablaCategoria />} />
        <Route path="categoriasInicio" element={<TablaCategoriaInicio />} />
        <Route path="aviso" element={<TablaAviso />} />
        <Route path="mensaje" element={<TablaMensaje />} />
        <Route path="contactanos" element={<TablaContactanos />} />
        <Route path="*" element={<Preloader />} />
      </Route>
    </Routes>
  );

  const getRoute = () => {
    if (loading) {
      return (
        <Routes>
          <Route path="/" element={<FullPageLoading />} />
        </Routes>
      );
    }

    if (!currentUser) return publicRoutes;

    if (currentUser) {
      return adminRoutes;
    }
  };

  return (
    <>
      <BrowserRouter>{getRoute()}</BrowserRouter>
    </>
  );
}

export default App;
