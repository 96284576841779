import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.services";

const ItemProductoGrid = ({ id, nombre, precio, descuento, imagenes }) => {
  const navigate = useNavigate();
  let precioFinal = precio;
  const [imagn, setImagen] = useState("https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif");

  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - descuentoProps;
  }

  useEffect(() => {
      getImagen();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes[0]);
      console.log("imagen ----"+id+nombre+":"+result.result);
      imagen = result.result;
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url(${imagen})`;
      setImagen(result.result);
      
    } catch (error) {
      toast.error(error);
    }
    finally{

    }
  };
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <a onClick={() => navigate(`/producto/${id}`)}>
        <div className="product__item">
          <div
            id={`imagenProducto${id}`}
            data-setbg={imagn}
          />
          <img className="lazyload" src={imagn} style={{ height: "260px", width:"262px", objectFit: "cover" }}  alt="" />
          <div className="product__item__text">
            <h6>{nombre}</h6>
            <h5>${Math.round(precioFinal)}.00</h5>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ItemProductoGrid;
