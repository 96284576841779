import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.services";
import FullPageLoading from "../../utils/FullPageLoading";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const NoticiaDetalles = () => {
  const { id } = useParams();
  const [noticia, setNoticia] = useState({});
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState(null);

  useEffect(() => {
    getNoticia();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (noticia.createdAt) {
      if (noticia.createdAt) {
        const fechaString = getFechaString(noticia.createdAt);
        document.getElementById(`fecha${id}`).innerHTML = fechaString;
      }
    }

    if (usuario.imagen != null) {
      getImage();
    }
  }, [noticia]);

  const getImage = async () => {
    try {
      const imagenProfile = await S3Service.get(usuario.imagen);
      document.getElementById(`perfil${id}`).src = imagenProfile.result;
    } catch (error) {
      console.log(error);
    }
  };

  const getNoticia = async () => {
    try {
      const data = await NoticiaService.getById(id);

      S3Service.get(data[0].imagen).then((dataimagen) => {
        document.getElementById(`imagen${data[0].imagen}`).src =
          dataimagen.result;
        setImagen(dataimagen.result);
      });

      setNoticia(data[0]);
      setUsuario(data[0].usuario);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <Helmet>
            <title>Noticia - {noticia.titulo}</title>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={noticia.titulo} />
            <meta property="og:image" content={imagen} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={noticia.titulo} />
            <meta
              property="twitter:description"
              content="Noticia de todo original outdoor."
            />
            <meta property="twitter:image" content={imagen} />
          </Helmet>
          {/* Blog Details Hero Begin */}
          <section className="blog-hero spad">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-9 text-center">
                  <div className="blog__hero__text">
                    <h2>{noticia.titulo}</h2>
                    <ul>
                      <li>
                        Autor: {usuario.nombre} {usuario.apellidoPaterno}
                      </li>
                      <li id={`fecha${id}`}>
                        {noticia.createdAt.split("T")[0]}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Blog Details Hero End */}
          {/* Blog Details Section Begin */}
          <section className="blog-details spad">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-12">
                  <div className="blog__details__pic">
                    <img
                      id={`imagen${noticia.imagen}`}
                      src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="blog__details__content">
                    <div
                      className="blog__details__share"
                      style={{ marginLeft: "-24px" }}
                    >
                      <span>Compartir</span>
                      <ul>
                        <li>
                          <a href="#">
                            {/* <i className="fa fa-facebook" /> */}
                            <li>
                              <FacebookShareButton
                                quote={noticia.titulo + " leer más en: "}
                                id="btn-fb"
                                url={window.location.href}
                              >
                                <a>
                                  <i class="fab fa-facebook-f"></i>
                                </a>
                              </FacebookShareButton>
                            </li>
                          </a>
                        </li>
                        <li>
                          <TwitterShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <a className="twitter">
                              <i class="fab fa-twitter"></i>
                            </a>
                          </TwitterShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <a className="linkedin">
                              <i class="fab fa-linkedin"></i>
                            </a>
                          </LinkedinShareButton>
                        </li>
                        <li >
                        <WhatsappShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <a className="whatsapp" style={{backgroundColor: "#25D366"}}>
                              <i class="fab fa-whatsapp"></i>
                            </a>
                          </WhatsappShareButton>
                        </li>
                      </ul>
                    </div>
                    <div className="blog__details__text">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: noticia.cuerpoNoticia,
                        }}
                      ></p>
                    </div>
                    <div className="blog__details__quote">
                      <i className="fa fa-quote-left" />
                      <p>{noticia.fraseCelebre}</p>
                      <h6>{noticia.autorFraseCelebre}</h6>
                    </div>
                    <div className="blog__details__text"></div>
                    <div className="blog__details__option">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="blog__details__author">
                            <div className="blog__details__author__pic">
                              <img
                                id={`perfil${id}`}
                                src="https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg"
                                alt=""
                              />
                            </div>
                            <div className="blog__details__author__text">
                              <h5>
                                {usuario.nombre} {usuario.apellidoPaterno}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="blog__details__tags">
                            {noticia.tag.split(", ").map((record, index) => {
                              return <a href="#">#{record}</a>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Blog Details Section End */}
        </>
      )}
    </>
  );
};

export default NoticiaDetalles;

const getMes = (fecha) => {
  let mes = "";
  switch (fecha) {
    case "01":
      mes = "Enero";
      break;
    case "02":
      mes = "Febrero";
      break;
    case "03":
      mes = "Marzo";
      break;
    case "04":
      mes = "Abril";
      break;
    case "05":
      mes = "Mayo";
      break;
    case "06":
      mes = "Junio";
      break;
    case "07":
      mes = "Julio";
      break;
    case "08":
      mes = "Agosto";
      break;
    case "09":
      mes = "Septiembre";
      break;
    case "10":
      mes = "Octubre";
      break;
    case "11":
      mes = "Noviembre";
      break;
    case "12":
      mes = "Diciembre";
      break;
    default:
      mes = "n/a";
  }
  return mes;
};

const getFechaString = (fecha) => {
  const fechaNumeros = fecha.split("T")[0];
  const year = fechaNumeros.split("-")[0];
  const mes = fechaNumeros.split("-")[1];
  const dia = fechaNumeros.split("-")[2];

  return `${dia} ${getMes(mes)} ${year}`;
};
