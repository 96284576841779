import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AvisoService from "../../services/Aviso.service";

const AvisoPrivate = () => {
  const [aviso, setAviso] = useState({undefined});
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = async () => {
    try {
      const av = await AvisoService.get();
      setAviso(av[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="blog__item">
            <p
              style={{
                textAlign: "justify",
                marginTop: "5%",
                padding: "3%",
                paddingBottom: "10%",
              }}
            >
              <h2>{aviso.titulo}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: aviso.cuerpo,
                }}
              ></p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvisoPrivate;
