/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.services";

const EliminarCarrusel = () => {
  const { id } = useParams();
  const [carrusel, setCarrusel] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await CarruselService.getById(id);
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCarrusel = async () => {
    setLoading(true);
    try {
      const carruselBorrado = await CarruselService.remove(id);
      toast.info("Item de carrusel eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/carrusel");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="grid-structure">
                  <div
                    className="grid-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h4 className="card-title">Eliminar item carrusel</h4>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Titulo
                        </label>
                        <input
                          value={carrusel.titulo}
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Subtitulo
                        </label>
                        <input
                          value={carrusel.subtitulo}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-lg-12">
                        <p>Imagen</p>
                      </div>
                    </div>

                    {carrusel.imagen ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={String(imagen)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* Botones */}
                    <div
                      className="row"
                      style={{ marginTop: "20px", textAlign: "center" }}
                    >
                      <div className="col-lg-12">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate("/carrusel")}
                          style={{ margin: "10px" }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          form="form1"
                          onClick={() => deleteCarrusel()}
                          style={{ margin: "10px" }}
                        >
                          Eliminar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarCarrusel;
