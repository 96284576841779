import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


import CategoriasInicioService from "../../../services/CategoriasInicio.service";
import S3Service from "../../../services/S3.services";

const CategoriasInicio = () => {
  const navigate = useNavigate();
  const [hombres,setHombres]=useState({});
  const [mujeres,setMujeres]=useState({});
  const [outdoors,setOutdoors]=useState({});

  useEffect(() => {
    getCategorias();
  }, []);

  const getCategorias = async () => {
    try {
      const caballeros=await CategoriasInicioService.getById(1);
      const imagenCaballeros = await S3Service.get(caballeros.imagen);
      caballeros.url=imagenCaballeros.result;
      setHombres(caballeros);

      const damas=await CategoriasInicioService.getById(2);      
      const imagenDamas = await S3Service.get(damas.imagen);
      damas.url=imagenDamas.result;
      setMujeres(damas);

      const out=await CategoriasInicioService.getById(3);      
      const imagenOut = await S3Service.get(out.imagen);
      out.url=imagenOut.result;
      setOutdoors(out);

    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  
  return (
    <section className="banner spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 offset-lg-4">
            <div className="banner__item">
              <div className="banner__item__pic">
                <img src="/img/banner/banner-1.jpg" alt="" />
               {/* <img src={hombres.url} alt="" />*/}
              </div>
              <div className="banner__item__text">
                <h2>Colección caballeros</h2>
                <a onClick={() => navigate("/tienda/hombre")}>Compra ahora</a>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner__item banner__item--middle">
              <div className="banner__item__pic">
                <img src="/img/banner/banner-2.jpg" alt="" />
                {/*<img src={mujeres.url} alt="" />*/}
              </div>
              <div className="banner__item__text">
                <h2>Colección dama</h2>
                <a onClick={() => navigate("/tienda/mujeres")}>Compra ahora</a>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="banner__item banner__item--last">
              <div className="banner__item__pic">
                <img src="/img/banner/banner-3.png" alt="" />
                {/*<img src={outdoors.url} alt="" />*/}
              </div>
              <div className="banner__item__text">
                <h2>Colección outdoors</h2>
                <a onClick={() => navigate("/tienda/Outdoors")}>Compra ahora</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoriasInicio;
