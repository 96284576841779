/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import {
  Button,
  getPaginationUtilityClass,
  TablePagination,
} from "@mui/material";

import FileOpenIcon from "@mui/icons-material/FileOpen";

import { toast } from "react-toastify";
import PagoDetalles from "./PagoDetalles";
//import ExportExcel from "react-export-excel";
/* Servicios */
import PagoService from "../../../services/Pago.service";

const TablaPagos = () => {
  const [pagos, setPagos] = useState([]);
  const [pagosArray, setPagosArray] = useState([]);
  const [pagosTodos, setPagosTodos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

{/*  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn;*/}

  useEffect(() => {
    corte();
    getPagos();
  }, [page, limit]);

  const getPagos = async () => {
    setLoading(true);
    try {
      const data = await PagoService.list(limit, page * limit);
      setPagos(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const corte = async () => {
    setLoading(true);
    try {
      const data = await PagoService.getAll();
      setPagosTodos(data);
      console.log("-------------" + data);
      data.forEach((pago) => {
        const newPago = {
          nombre: pago.usuario.nombre,
          correo: pago.usuario.email,
          direccion: pago.usuario.direccion,
          precio: pago.precio,
          status: pago.status,
        };
        setPagosArray((pagosArray) => [...pagosArray, newPago]);
      });
      console.log("----0" + pagosArray);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <h4
                        className="card-title"
                        style={{ marginRight: "20px", marginTop: "6px" }}
                      >
                        Pagos
                      </h4>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => navigate(`corte`)}
                        style={{}}
                      >
                        Corte
                      </Button>
                      {/*<ExportExcel.ExcelFile
                        element={
                          <Button
                            variant="contained"
                            style={{ marginLeft: "5%" }}
                            color="success"
                          >
                            Exportar{" "}
                            <FileOpenIcon style={{ marginLeft: "10%" }} />
                          </Button>
                        }
                        filename={"corte"}
                      >
                        <ExportExcel.ExcelFile.ExcelSheet data={pagosArray} name="Pagos">
                          <ExportExcel.ExcelFile.ExcelColumn
                            label="Nombre"
                            value="nombre"
                            style="fgColor:FF0000FF"
                          />
                          <ExportExcel.ExcelFile.ExcelColumn label="Correo" value="correo" />
                          <ExportExcel.ExcelFile.ExcelColumn label="Direccion" value="direccion" />
                          <ExportExcel.ExcelFile.ExcelColumn label="Pago" value="precio" />
                          <ExportExcel.ExcelFile.ExcelColumn label="Estado" value="status" />
                          <ExportExcel.ExcelFile.ExcelColumn label="" value="" />
                        </ExportExcel.ExcelFile.ExcelSheet>
                      </ExportExcel.ExcelFile>*/}
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table" id="tablapagos">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Correo</th>
                        <th scope="col">Direccion</th>
                        <th scope="col">Pago</th>
                        <th scope="col">Ver detalles</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagos.map((record, index) => {
                        return <PagoDetalles id={record.id} data={record} />;
                      })}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  classes="recent-orders"
                  labelRowsPerPage="Items por página"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TablaPagos;
