import React, { useEffect } from "react";
import PreviewProductos from "./PreviewProductos";
import CategoriasInicio from "./CategoriasInicio";
import Carrusel from "./Carrusel";
import Noticias from "./Noticias";
import { Helmet } from "react-helmet";

const ShopHome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>De todo original outdoors</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="De todo original outdoors" />
        <meta property="og:image" content={"/LogoOf.png"} />
      </Helmet>
      <Carrusel />
      <CategoriasInicio />
      <PreviewProductos />
      <Noticias />
    </>
  );
};

export default ShopHome;
