import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';

// Generate Sales Data
function createData(mes, amount) {
  return { mes, amount };
}

const data = [
  createData('1', 0),
  createData('2', 300),
  createData('3', 600),
  createData('4', 800),
  createData('5', 1500),
  createData('6', 2000),
  createData('7', 2400),
  createData('8', 2400),
  createData('9', 2400),
  createData('10', 2600),
  createData('11', 2600),
  createData('12', 2700),
  createData('13', 2700),
  createData('14', 2700),
  createData('15', 2800),
  createData('16', 2800),
  createData('17', 2900),
  createData('18', 2900),
  createData('19', undefined),
  createData('20', undefined),
  createData('21', undefined),
  createData('22', undefined),
  createData('23', undefined),
  createData('24', undefined),
  createData('25', undefined),
  createData('26', undefined),
  createData('27', undefined),
  createData('28', undefined),
  createData('29', undefined),
  createData('30', undefined),
  createData('31', undefined),

];

export default function Chart() {
  const theme = useTheme();

  return (
    <React.Fragment>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Ventas del mes
    </Typography>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis
            dataKey="mes"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
          </XAxis>
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: 'middle',
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Ventas ($)
            </Label>
          </YAxis>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="amount"
            stroke={theme.palette.primary.main}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}