import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ProductoService from "../../../services/Producto.service";
import S3Service from "../../../services/S3.services";
import { Button, TablePagination } from "@mui/material";
import FullPageLoading from "../../utils/FullPageLoading";
import ItemProductoGrid from "./ItemProductoGrid";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CategoriaService from "../../../services/Categoria.service";

const TiendaCompleta = () => {
  const navigate = useNavigate();
  const [categorias, setCategorias] = useState([]);
  const { filtroI } = useParams();

  const [loading, setLoading] = useState(true);
  const [productos, setProductos] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const [filtro, setFiltro] = useState(filtroI);
  const [tag, setTag] = useState(false);
  const [filtroPrecio, setFiltroPrecio] = useState({
    minimo: undefined,
    maximo: undefined,
  });
  const [filtroActual, setFiltroActual] = useState("");
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (filtroI != undefined && reset == false) {
      if (filtroI != "Todos") {
        setFiltro(filtroI);
        getProductos();
      }
    } else {
      getProductos();
      getCategorias();
    }

    window.scrollTo(0, 0);
  }, [filtro, page, limit]);

  const getProductos = async () => {
    setLoading(true);
    try {
      if (filtro == undefined && filtroI==undefined) {
        try {
          console.log("***TODO");
          setProductos([]);
          const data = await ProductoService.list(limit, page * limit);
          setProductos(data.data);
          setTotal(data.total);
          setFiltroActual("Todo");
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else if (filtro == "mayor-menor") {
        try {
          console.log("***MAYOR-MENOR");
          const data = await ProductoService.listByPrice(
            limit,
            page * limit,
            "mayor-menor"
          );
          setProductos(data.data);
          setTotal(data.total);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else if (filtro == "menor-mayor") {
        try {
          console.log("***MENOR-MAYOR");
          const data = await ProductoService.listByPrice(
            limit,
            page * limit,
            "menor-mayor"
          );
          setProductos(data.data);
          setTotal(data.total);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else if (filtro == "hombre") {
        try {
          console.log("***HOMBRE");
          setProductos([]);
          const data = await ProductoService.listByCategory(
            limit,
            page * limit,
            "Caballero"
          );
          setProductos(data.data);
          setTotal(data.total);
          setFiltroActual("Hombre");
          console.log("paquetes hombre:" + JSON.stringify(data.data));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          console.log("paquetes hombre finally:" + JSON.stringify(productos));
        }
      } else if (filtro == "mujeres") {
        setProductos([]);
        try {
          console.log("***MUJERES");
          
          const data = await ProductoService.listByCategory(
            limit,
            page * limit,
            "Dama"
          ).then((data) => {
            //do any results transformations
            setProductos(data.data);
            setProductos(data.data);
            setTotal(data.total);
            setFiltroActual("Mujeres");
            console.log("paquetes mujeres:" + JSON.stringify(data.data));
            return data;
          });
          setProductos(data.data);
          setTotal(data.total);
          setFiltroActual("Mujeres");
          console.log("paquetes mujeres:" + JSON.stringify(data.data));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          console.log("paquetes mujeres finally:" + JSON.stringify(productos));
        }
      } else if (filtro == "unisex") {
        try {
          console.log("***UNISEX");
          setProductos([]);
          const data = await ProductoService.listByCategory(
            limit,
            page * limit,
            "Unisex"
          );
          setProductos(data.data);
          setTotal(data.total);
          console.log("paquetes unisex:" + JSON.stringify(data.data));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          console.log("paquetes unisex finally:" + JSON.stringify(productos));
        }
      } else if (filtro == "Unisex") {
        try {
          console.log("***UNISEX");
          setProductos([]);
          const data = await ProductoService.listByCategory(
            limit,
            page * limit,
            "Unisex"
          );
          setProductos(data.data);
          setTotal(data.total);
          setFiltroActual("Unisex");
          console.log("paquetes Unisex:" + JSON.stringify(data.data));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          console.log("paquetes Unisex finally:" + JSON.stringify(productos));
        }
      } else if (filtro == "Outdoors") {
        setProductos([]);
        try {
          console.log("***OUTDOORS");
          const data = await ProductoService.listByCategory(
            limit,
            page * limit,
            "Outdoors"
          );
          setProductos(data.data);
          setTotal(data.total);
          setFiltroActual("Outdoors");
          console.log("paquetes Outdoors:" + JSON.stringify(data.data));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          console.log("paquetes Outdoors finally:" + JSON.stringify(productos));
        }
      } else if (filtro == "0-500") {
        setProductos([]);
        setFiltroPrecio({ minimo: 0, maximo: 500 });
        const data = await ProductoService.listByRangePrice(
          limit,
          page * limit,
          0,
          500
        );
        setProductos(data.data);
        setTotal(data.total);
        setFiltroActual("Todo");
      } else if (filtro == "500-1000") {
        setProductos([]);
        setFiltroPrecio({ minimo: 500, maximo: 1000 });
        const data = await ProductoService.listByRangePrice(
          limit,
          page * limit,
          500,
          1000
        );
        setProductos(data.data);
        setTotal(data.total);
        setFiltroActual("Todo");
      } else if (filtro == "1000-1500") {
        setProductos([]);
        setFiltroPrecio({ minimo: 1000, maximo: 1500 });
        const data = await ProductoService.listByRangePrice(
          limit,
          page * limit,
          1000,
          1500
        );
        setProductos(data.data);
        setTotal(data.total);
        setFiltroActual("Todo");
      } else if (filtro == "1500-2000") {
        setProductos([]);
        setFiltroPrecio({ minimo: 1500, maximo: 2000 });
        const data = await ProductoService.listByRangePrice(
          limit,
          page * limit,
          1500,
          2000
        );
        setProductos(data.data);
        setTotal(data.total);
        setFiltroActual("Todo");
      } else if (filtro == "2000-2500") {
        setProductos([]);
        setFiltroPrecio({ minimo: 2000, maximo: 2500 });
        const data = await ProductoService.listByRangePrice(
          limit,
          page * limit,
          2000,
          2500
        );
        setProductos(data.data);
        setTotal(data.total);
        setFiltroActual("Todo");
      } else if (filtro == "2500-100000") {
        setProductos([]);
        setFiltroPrecio({ minimo: 2500, maximo: "Todo" });
        const data = await ProductoService.listByRangePrice(
          limit,
          page * limit,
          2500,
          100000
        );
        setProductos(data.data);
        setTotal(data.total);
        setFiltroActual("Todo");
      } else if (tag == true) {
        setProductos([]);
        setFiltroActual(filtro);
        setTag(false);
        const data = await ProductoService.listByTag(
          limit,
          page * limit,
          filtro
        );
        setProductos(data.data);
        setTotal(data.total);
      } else {
        setProductos([]);
        const data = await ProductoService.list(limit, page * limit);
        setProductos(data.data);
        setTotal(data.total);
        setFiltroPrecio({ minimo: undefined, maximo: undefined });
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchByTag = async (tag) => {
    const data = await ProductoService.listByTag(limit, page * limit, tag);
    console.log(data.data);
    setProductos(data.data);
    setTotal(data.total);
  };

  const getProductoPrecio = (e) => {
    console.log(e);
    if (e === "normal") {
      setFiltro("normal");
    } else if (e === "menor-mayor") {
      setFiltro("menor-mayor");
    } else if (e === "mayor-menor") {
      setFiltro("mayor-menor");
    }
  };

  const getProdcutoMarca = (e) => {
    if (e === "hombre") {
      setFiltro("hombre");
    } else if (e === "mujeres") {
      setFiltro("mujeres");
    } else if (e === "unisex") {
      setFiltro("Unisex");
    } else if (e === "Outdoors") {
      setFiltro("Outdoors");
    }
  };

  const colapsarCategorias = () => {
    try {
      const categoriaElemento = document.querySelector(`#collapseOne`);
      categoriaElemento.className == "collapse show"
        ? (categoriaElemento.className = "collapsing")
        : (categoriaElemento.className = "collapse show");
    } catch (error) {
      console.log(error);
    }
  };

  const colpasarPrecios = () => {
    try {
      const categoriaElemento = document.querySelector(`#collapseThree`);
      categoriaElemento.className == "collapse show"
        ? (categoriaElemento.className = "collapsing")
        : (categoriaElemento.className = "collapse show");
    } catch (error) {
      console.log(error);
    }
  };

  const colapsarEtiquetas = () => {
    try {
      const categoriaElemento = document.querySelector(`#collapseSix`);
      categoriaElemento.className == "collapse show"
        ? (categoriaElemento.className = "collapsing")
        : (categoriaElemento.className = "collapse show");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <Helmet>
            <title>De todo original outdoors - Tienda</title>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="De todo original outdoors" />
            <meta property="og:image" content={"/LogoOf.png"} />
          </Helmet>
          {/* Breadcrumb Section Begin */}
          <section className="breadcrumb-option">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb__text">
                    <h4>Tienda</h4>
                    <div className="breadcrumb__links">
                      <a href="/">Inicio</a>
                      <span>Tienda</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Breadcrumb Section End */}
          {/* Shop Section Begin */}
          <section className="shop spad" style={{ marginTop: "-6%" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="shop__sidebar">
                    <div className="shop__sidebar__accordion">
                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-heading">
                            <a
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              onClick={() => colapsarCategorias()}
                            >
                              Categorias
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse show"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">
                              <div className="shop__sidebar__categories">
                                <ul className="nice-scroll">
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setReset(true);
                                        setFiltro(undefined);
                                        navigate("/tienda");
                                      }}
                                    >
                                      Todo{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setReset(true);
                                        setFiltro(undefined);
                                        getProdcutoMarca("hombre");
                                        navigate("/tienda");
                                      }}
                                    >
                                      Hombre{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setReset(true);
                                        setFiltro(undefined);
                                        getProdcutoMarca("mujeres");
                                        navigate("/tienda");
                                      }}
                                    >
                                      Mujeres{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => getProdcutoMarca("unisex")}
                                    >
                                      Unisex{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        getProdcutoMarca("Outdoors")
                                      }
                                    >
                                      Outdoors{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-heading">
                            <a
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              onClick={() => colpasarPrecios()}
                            >
                              Filtrar por precio
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse show"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">
                              <div className="shop__sidebar__price">
                                <ul>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setReset(true);
                                        setFiltro(undefined);
                                        setFiltroPrecio({
                                          minimo: 0,
                                          maximo: "Infinito",
                                        });
                                        navigate("/tienda");
                                      }}
                                    >
                                      Todos los precios
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setFiltro("0-500")}
                                    >
                                      $0.00 - $500.00
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setFiltro("500-1000")}
                                    >
                                      $500.00 - $1000.00
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setFiltro("1000-1500")}
                                    >
                                      $1000.00 - $1500.00
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setFiltro("1500-2000")}
                                    >
                                      $1500.00 - $2000.00
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setFiltro("2000-2500")}
                                    >
                                      $2000.00 - $2500.00
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setFiltro("2500-100000")}
                                    >
                                      $2500.00 +
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-heading">
                            <a
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              onClick={() => colapsarEtiquetas()}
                            >
                              Etiquetas
                            </a>
                          </div>
                          <div
                            id="collapseSix"
                            className="collapse show"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body">
                              <div className="shop__sidebar__tags">
                                {categorias.map((record, index) => {
                                  return (
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setFiltro(record.tipo);
                                        setTag(true);
                                      }}
                                    >
                                      {record.tipo}
                                    </a>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="shop__product__option">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="shop__product__option__left">
                          <p>
                            Mostrando {productos.length} de {total} productos
                            <p style={{ fontWeight: "bold" }}>
                              Rango de precio: ${filtroPrecio.minimo}-$
                              {filtroPrecio.maximo} | Categoria: {filtroActual}
                            </p>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="shop__product__option__right">
                          <p>Ordenar por precio:</p>
                          <select
                            className="nice-select"
                            style={{ fontWeight: "bold" }}
                            onChange={(e) => {
                              setFiltro(e.target.value);
                              console.log("--" + e.target.value);
                            }}
                            value={filtro}
                          >
                            <option disabled="disabled" className="current">
                              -
                            </option>
                            <option value="normal" className="current">
                              Aleatorio
                            </option>
                            <option value="menor-mayor" className="current">
                              Mas bajo al mas alto
                            </option>
                            <option value="mayor-menor" className="current">
                              Mas alto al mas bajo
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {productos.map((record, index) => {
                      if (filtroPrecio.minimo != undefined) {
                        if (
                          record.preciodescuento >= filtroPrecio.minimo &&
                          record.preciodescuento <= filtroPrecio.maximo
                        ) {
                          if (record.descuento) {
                            return (
                              <ItemProductoGrid
                                id={record.id}
                                nombre={record.nombre}
                                precio={record.precio}
                                descuento={record.descuento}
                                imagenes={record.imagenes}
                              />
                            );
                          } else {
                            return (
                              <ItemProductoGrid
                                id={index}
                                nombre={record.nombre}
                                precio={record.precio}
                                imagenes={record.imagenes}
                              />
                            );
                          }
                        }
                      } else {
                        if (record.descuento) {
                          return (
                            <ItemProductoGrid
                              id={record.id}
                              nombre={record.nombre}
                              precio={record.precio}
                              descuento={record.descuento}
                              imagenes={record.imagenes}
                            />
                          );
                        } else {
                          return (
                            <ItemProductoGrid
                              id={index}
                              nombre={record.nombre}
                              precio={record.precio}
                              imagenes={record.imagenes}
                            />
                          );
                        }
                      }
                    })}
                  </div>
                </div>
                {/* <div className="col-lg-12">
                <div className="row"> */}
                <div className="col-lg-12">
                  <div className="product__pagination">
                    {/* <a className="active" onClick={() => console.log("A")}>
                      1
                    </a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <span>...</span>
                    <a href="#">21</a> */}
                    <TablePagination
                      style={{ color: "var(--color-dark-variant)" }}
                      component="div"
                      classes="recent-orders"
                      labelRowsPerPage="Items por página"
                      //  rowsPerPageOptions={[]}
                      count={total}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
                {/* </div>
                </div> */}
              </div>
            </div>
          </section>
          {/* Shop Section End */}
        </>
      )}
    </>
  );
};

export default TiendaCompleta;
