import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TablePagination } from "@mui/material";

import AvisoService from "../../services/Aviso.service";

const Aviso = () => {
  const [aviso, setAviso] = useState({ undefined });

  useEffect(() => {
    window.scrollTo(0, 0);
    getAviso();
  }, []);

  const getAviso = async () => {
    try {
      const av = await AvisoService.get();
      setAviso(av[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* Breadcrumb Section Begin */}
      <section
        className="breadcrumb-blog set-bg"
        data-setbg="https://imcp.org.mx/wp-content/uploads/2021/05/OAXACA.jpg"
        style={{
          backgroundImage: `url(https://imcp.org.mx/wp-content/uploads/2021/05/OAXACA.jpg)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "200px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>{aviso.titulo}</h2>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="blog__item" style={{padding:"5%"}}>
            <p style={{textAlign:"justify"}}
              dangerouslySetInnerHTML={{
                __html: aviso.cuerpo,
              }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aviso;
