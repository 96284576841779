/* React */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.services";

const NuevoUsuario = () => {
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const navigate = useNavigate();

  const saveUsuario = async () => {
    if (!usuario.nombre) {
      toast.info("Error, tienes que colocar un nombre");
    } else if (!usuario.apellidoPaterno) {
      toast.info("Error, tienes que colocar un apellido paterno");
    } else if (!usuario.apellidoMaterno) {
      toast.info("Error, tienes que colocar un apellido materno");
    } else if (!usuario.correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!usuario.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
    } else if (!usuario.numeroTelefonico) {
      toast.info("Error, tienes que colocar un numero telefonico");
    } else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } else {
      setLoading(true);
      try {
        const datosUsuario = usuario;
        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          datosUsuario.imagen = uploadPhoto.result.data;
          await UserService.create(datosUsuario);
        } else {
          await UserService.create(datosUsuario);
        }

        toast.success("Usuario creado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/usuarios");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div style={{ display: "flex" }}>
                  <h4 className="card-title">Nuevo usuario</h4>
                </div>
                <div className="grid-structure">
                  <div
                    className="grid-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Nombre
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({ ...usuario, nombre: e.target.value })
                          }
                          value={usuario.nombre}
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Apellido paterno
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              apellidoPaterno: e.target.value,
                            })
                          }
                          value={usuario.apellidoPaterno}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Apellido Materno
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              apellidoMaterno: e.target.value,
                            })
                          }
                          value={usuario.apellidoMaterno}
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Correo
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({ ...usuario, correo: e.target.value })
                          }
                          value={usuario.correo}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Contraseña
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              contraseña: e.target.value,
                            })
                          }
                          value={usuario.contraseña}
                          type="password"
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Numero telefonico
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              numeroTelefonico: e.target.value,
                            })
                          }
                          value={usuario.numeroTelefonico}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "45px" }}>
                      <div className="col-lg-12">
                        <p>Imagen</p>
                        <DropZone
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>

                    {file ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* Botones */}
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-lg-6">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate("/usuarios")}
                        >
                          Cancelar
                        </Button>
                      </div>
                      <div className="col-lg-6">
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => saveUsuario()}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NuevoUsuario;
