import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardCantidad from "./CardCantidad";
import Chart from "./Chart";
import { toast } from "react-toastify";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

/* Servicios */
import PagoService from "../../../services/Pago.service";
import ProductoService from "../../../services/Producto.service";

const Dashboard = () => {
  const [productos, setProductos] = useState([]);
  const [totalPagos, setTotalPagos] =  useState(0);
  const [pagosEnProceso, setPagosEnProceso] =  useState(0);
  const [pagosTerminado, setTerminado] =  useState(0);
  const [pagosCancelado, setPagosCancelado] =  useState(0);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getProductos();
    getPagos();
    navigate("/");
  }, [page, limit]);

  const getProductos = async () => {
    setLoading(true);
    try {
      const data = await ProductoService.getAll();
      setTotal(data.length);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getPagos = async () => {
    setLoading(true);
    try {
      const totalpagos = await PagoService.totalPagos();
      setTotalPagos(totalpagos.total);
      const enproceso = await PagoService.countPagosByStatus("EnProceso");
      setPagosEnProceso(enproceso.total);
      const terminado = await PagoService.countPagosByStatus("Terminado");
      setTerminado(terminado.total);
      const cancelado = await PagoService.countPagosByStatus("Cancelado");
      setPagosCancelado(cancelado.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                {<Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 180,
                    backgroundColor: "rgba(200, 200, 200, 0.4)"
                  }}
                >
                 <CardCantidad mainText={"Productos"} total={total} secondaryText={"En venta"} ver={"Ver productos"} link={"/producto"} />
                </Paper>}
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                {<Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 180,
                    backgroundColor: "rgba(139, 195, 74, 1)"
                  }}
                >
                 <CardCantidad mainText={"Ventas Exitosas"} total={pagosTerminado} secondaryText={"En este mes"} ver={"Ver pagos"}  link={"/pagos"}/>
                </Paper>}
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                {<Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 180,
                    backgroundColor: "rgba(255, 152, 0, 1)"
                  }}
                >
                 <CardCantidad mainText={"Ventas En proceso"} total={pagosEnProceso} secondaryText={"En este mes"} ver={"Ver pagos"}  link={"/pagos"}/>
                </Paper>}
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                {<Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 180,
                    backgroundColor: "rgba(244, 67, 54, 1)"
                  }}
                >
                 <CardCantidad mainText={"Ventas Canceladas"} total={pagosCancelado} secondaryText={"En este mes"} ver={"Ver pagos"}  link={"/pagos"}/>
                </Paper>}
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {/* Chart 
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 290,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid>*/}
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                <CardCantidad mainText={"N° de Ventas Total"} total={totalPagos} secondaryText={"En este mes"} ver={"Ver pagos"}  link={"/pagos"}/>
                </Paper>
              </Grid>
              </Grid>

          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
