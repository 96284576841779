/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid
} from "@mui/material";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";
import { Delete, Visibility } from "@material-ui/icons";
import { grey,lightBlue,red,purple } from '@mui/material/colors';

// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";

/* Servicios */
import MensajeService from "../../../services/Mensaje.service";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const MensajeDetalles = ({ id, data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mensaje, setMensaje] = useState(data);
  const [visible, setVisible] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: grey[500],
      },
      secondary: {
        main: lightBlue[400],
      },
      success: {
        main: "#536dfe",
      },
    },
  });

  useEffect(() => {
    loadMensaje();
  }, []);
  const visibleCheck = (valor) => {
    loadMensaje();
    setVisible(valor);
    document.getElementById("root").style.filter = "blur(1px)";
    if (valor == false) {
      document.getElementById("root").style.filter = "blur(0px)";
    }
  };

  const loadMensaje = async () => {
    setLoading(true);
    try {
      const result = await MensajeService.getById(id);
      setMensaje(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const estado = (state) => {
    if (state == "SinAbrir") {
      return (
        <>
        <ThemeProvider theme={theme}>
          <Badge
            badgeContent={"SinAbrir"}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{marginTop:"5%", backgroundColor: '#686868'}}
            
          ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "Leido") {
      return (
        <>
        <ThemeProvider theme={theme}>
          <Badge
            badgeContent={"Leído"}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{marginTop:"5%"}}
          ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "EnProceso") {
      return (
        <>
        <ThemeProvider theme={theme}>
          <Badge
            badgeContent={"EnProceso"}
            color="warning"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{marginTop:"5%"}}
          ></Badge>
          </ThemeProvider>
        </>
      );
    }
    if (state == "Cerrado") {
      return (
        <>
        <ThemeProvider theme={theme}>
          <Badge badgeContent={"Cerrado"} color="success" anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{marginTop:"5%"}}></Badge>
            </ThemeProvider>
        </>
      );
    }
    if (state == "Revisar") {
      return (
        <>
        <ThemeProvider theme={theme}>
          <Badge badgeContent={"Revisar"} color="error" anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{marginTop:"5%"}}></Badge>
            </ThemeProvider>
        </>
      );
    }
  };

  const terminarPago = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de terminar la venta?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Terminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {  
          mensaje.status="Terminado"        
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const leidoMensaje = async () => {
    try {     
          if(mensaje.Status=="SinAbrir"){
            mensaje.Status="Leido"        
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          }
          
    } catch (error) {
      toast.error(error);
    }
  };

  const enprocesoMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cambiar el estado a: EN PROCESO ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {  
          mensaje.Status="EnProceso"        
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const cerradoMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cambiar el estado a: CERRADO ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {  
          mensaje.Status="Cerrado"        
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const revisarMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de cambiar el estado a: REVISAR ?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {  
          mensaje.Status="Revisar"        
          MensajeService.update(mensaje)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const eliminarMensaje = async () => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de eliminar el pago?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          MensajeService.remove(id)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });

          window.location.reload();
        }
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {loading && mensaje != undefined ? (
        <Preloader />
      ) : (
        <tr key={id}>
          <th scope="row">{id}</th>
          <td>{mensaje.Nombre}</td>
          <td>{mensaje.Email}</td>
          <td>
            {" "}
            <IconButton 
            aria-label="ver" 
            onClick={() =>{ 
              visibleCheck(!visible);
              leidoMensaje();
            }}
            style={{marginTop:"-4%"}}
            >
              <Visibility />
            </IconButton>
          </td>
          <td>
            {"   "}
            {estado(mensaje.Status)}
          </td>
          <td>
            {" "}
            <IconButton aria-label="delete" onClick={() => eliminarMensaje(id)} style={{marginTop:"-4%"}}>
              <Delete />
            </IconButton>
          </td>
        </tr>
      )}
 <ThemeProvider theme={theme}>
      <CModal scrollable visible={visible} onClose={() => visibleCheck(false)}>
        <CModalHeader>
          <CModalTitle>Mensaje</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <TableContainer component={Paper}  >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Nombre</p>
                  </TableCell>
                  <TableCell>
                    <p>
                      {mensaje.Nombre} 
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Email</p>
                  </TableCell>
                  <TableCell>
                    <p>{mensaje.Email}</p>
                  </TableCell>
                </TableRow>  
                <TableRow>
                  <TableCell>
                    <p>{mensaje.MensajeCuerpo}</p>
                  </TableCell>
                </TableRow>
                <TableRow >
                  <TableCell>
                    <div className="row">
                    <Button
                        aria-label="delete"
                        color="warning"
                        onClick={() => enprocesoMensaje(id)}
                      >
                        En proceso
                      </Button>
                      <Button
                        aria-label="delete"
                        color="success"
                        onClick={() => cerradoMensaje(id)}
                      >
                        Cerrado
                      </Button>
                      <Button
                        aria-label="delete"
                        color="error"
                        onClick={() => revisarMensaje(id)}
                      >
                        Revisar
                      </Button>
                      {"  "}

                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </CModalBody>
      </CModal>
      </ThemeProvider>
    </>
  );
};

export default MensajeDetalles;
