import React from "react";
import { useNavigate } from "react-router-dom";

const CarritoVacio = () => {
    const navigate = useNavigate();
  return (
    <section className="breadcrumb-option">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb__text">
              <h4>Carrito de compras</h4>
              <div className="breadcrumb__links">
                <a style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                  Inicio
                </a>
                <a style={{ cursor: "pointer" }} onClick={() => navigate("/tienda")}>
                  Tienda
                </a>
                <span>Carrito de compras</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="cart-content space-mt--r130 space-mb--r130">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="item-empty-area text-center">
                      <div className="item-empty-area__icon space-mb--30">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M169.6 377.6c-22.882 0-41.6 18.718-41.6 41.601 0 22.882 18.718 41.6 41.6 41.6s41.601-18.718 41.601-41.6c-.001-22.884-18.72-41.601-41.601-41.601zM48 51.2v41.6h41.6l74.883 151.682-31.308 50.954c-3.118 5.2-5.2 12.482-5.2 19.765 0 27.85 19.025 41.6 44.825 41.6H416v-40H177.893c-3.118 0-5.2-2.082-5.2-5.2 0-1.036 2.207-5.2 2.207-5.2l20.782-32.8h154.954c15.601 0 29.128-8.317 36.4-21.836l74.882-128.8c1.237-2.461 2.082-6.246 2.082-10.399 0-11.446-9.364-19.765-20.8-19.765H135.364L115.6 51.2H48zm326.399 326.4c-22.882 0-41.6 18.718-41.6 41.601 0 22.882 18.718 41.6 41.6 41.6S416 442.082 416 419.2c0-22.883-18.719-41.6-41.601-41.6z" />
                        </svg>
                      </div>
                      <div className="item-empty-area__text">
                        <p className="space-mb--30">
                          No tienes productos en tu carrito :(
                        </p>
                        <a
                           style={{ cursor: "pointer" }} onClick={() => navigate("/tienda")}
                        >
                          Ir a comprar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarritoVacio;
