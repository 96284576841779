/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

/* Servicios */
import CategoriaService from "../../../services/Categoria.service";

const TablaCategoria = () => {
  const [categorias, setCategorias] = useState({});
  const [categoria, setCategoria] = useState({});
  const [categoriaEdit, setCategoriaEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenEliminar = () => {
    setOpenEliminar(true);
  };
  const handleCloseEliminar = () => {
    setOpenEliminar(false);
  };


  useEffect(() => {
    getCategorias();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveCategoria = async () => {
    if (!categoria.tipo) {
      toast.info("Error, tienes que colocar un tipo");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoria;
        await CategoriaService.create(datosCategoria);
        console.log("save");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getCategorias();
      }
    }
  };

  const updateCategoria = async () => {
    if (!categoriaEdit.tipo) {
      toast.info("Error, tienes que colocar un tipo");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoriaEdit;
        await CategoriaService.update(datosCategoria);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        handleCloseEdit();
        getCategorias();
      }
    }
  };

  const deleteCategoria = async () => {
    setLoading(true);
    try {
      const carruselBorrado = await CategoriaService.remove(categoriaEdit.id);
      toast.info("Item de carrusel eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);      
      handleCloseEliminar();
      getCategorias();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex" }}>
                    <h4
                      className="card-title"
                      style={{ marginRight: "20px", marginTop: "6px" }}
                    >
                      Etiquetas
                    </h4>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Tipo</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {categorias.map((record, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{record.id}</th>
                            <td>{record.tipo}</td>
                            <td>
                              {" "}
                              <span
                                className="material-icons-sharp"
                                onClick={() =>
                                  {setCategoriaEdit(record);
                                    handleOpenEdit();
                                  }
                                }
                              >
                                {" "}
                                edit{" "}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <span
                                className="material-icons-sharp"
                                onClick={() =>
                                  {setCategoriaEdit(record);
                                    handleOpenEliminar();
                                  }
                                }
                              >
                                {" "}
                                delete{" "}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  style={{ color: "var(--color-dark-variant)" }}
                  component="div"
                  classes="recent-orders"
                  labelRowsPerPage="Items por página"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex" }}>
                    <h4
                      className="card-title"
                      style={{ marginRight: "20px", marginTop: "6px" }}
                    >
                      Agregar etiqueta
                    </h4>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table" >
                        <tbody>
                          <th>
                            <p style={{marginTop:"80%"}}>Tipo</p>                            
                          </th>
                          <th>
                            <input
                              onChange={(e) =>
                                setCategoria({
                                  ...categoria,
                                  tipo: e.target.value,
                                })
                              }
                              value={categoria.tipo}
                              className={`form-control`}
                              style={{marginTop:"10%"}}
                            />
                          </th>
                          <th>
                            <Button
                              variant="outlined"
                              color="success"
                              onClick={() => saveCategoria()}
                              style={{marginTop:"20%"}}
                            >
                              Guardar
                            </Button>
                          </th>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/** EDITAR */}                    
            <Modal
              open={openEdit}
              onClose={handleCloseEdit}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Editar etiqueta
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <table className="table">
                    <tbody>
                      <th>Tipo</th>
                      <th>
                        <input
                          onChange={(e) =>
                            setCategoriaEdit({
                              ...categoriaEdit,
                              tipo: e.target.value,
                            })
                          }
                          value={categoriaEdit.tipo}
                          className={`form-control`}
                        />
                      </th>
                      <th>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => updateCategoria()}
                        >
                          Guardar
                        </Button>
                      </th>
                    </tbody>
                  </table>
                </Typography>
              </Box>
            </Modal>
            {/** ELIMINAR */}
            <Modal
              open={openEliminar}
              onClose={handleCloseEliminar}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Eliminar etiqueta
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <table className="table">
                    <tbody>
                      <th>Tipo</th>
                      <th>
                        {categoriaEdit.tipo}
                      </th>
                      <th>
                      <Button
                          variant="outlined"
                          color="error"
                          form="form1"
                          onClick={() => deleteCategoria()}
                        >
                          Eliminar
                        </Button>
                      </th>
                    </tbody>
                  </table>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaCategoria;
