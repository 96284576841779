/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import S3Service from "../../../services/S3.services";
import UserService from "../../../services/User.Service";

const MiCuenta = () => {
  const { currentUser } = useContext(AuthContext);
  const id = currentUser.id;
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UserService.getById(id);
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        console.log(imagen);
        setImagen(imagen.result);
      }
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actualizarUsuario = async () => {
    if (usuario.nombre.length <= 0 || !usuario.nombre) {
      toast.info("Error, tienes que colocar un nombre");
    } else if (!usuario.apellidoPaterno) {
      toast.info("Error, tienes que colocar un apellido paterno");
    } else if (!usuario.apellidoMaterno) {
      toast.info("Error, tienes que colocar un apellido materno");
    } else if (!usuario.correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!usuario.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
    } else if (!usuario.numeroTelefonico) {
      toast.info("Error, tienes que colocar un numero telefonico");
    } else {
      setLoading(true);
      try {
        let data = {};
        if (password.length >= 1) {
          data = {
            id: usuario.id,
            nombre: usuario.nombre,
            apellidoPaterno: usuario.apellidoPaterno,
            apellidoMaterno: usuario.apellidoMaterno,
            correo: usuario.correo,
            numeroTelefonico: usuario.numeroTelefonico,
            contraseña: password,
          };
        } else {
          data = {
            id: usuario.id,
            nombre: usuario.nombre,
            apellidoPaterno: usuario.apellidoPaterno,
            apellidoMaterno: usuario.apellidoMaterno,
            correo: usuario.correo,
            numeroTelefonico: usuario.numeroTelefonico,
          };
        }

        if (file) {
          await S3Service.delete(usuario.imagen);
          const uploadPhoto = await S3Service.upload(file);
          data.imagen = uploadPhoto.result.data;
        }

        const userActualizado = await UserService.update(data);
        console.log(userActualizado);
        toast.success("Usuario actualizado con exito");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/usuarios");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container-fluid">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div style={{ display: "flex" }}>
                  <h4 className="card-title">Mi cuenta</h4>
                </div>
                <div className="grid-structure">
                  <div
                    className="grid-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Nombre
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({ ...usuario, nombre: e.target.value })
                          }
                          value={usuario.nombre}
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Apellido paterno
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              apellidoPaterno: e.target.value,
                            })
                          }
                          value={usuario.apellidoPaterno}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Apellido Materno
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              apellidoMaterno: e.target.value,
                            })
                          }
                          value={usuario.apellidoMaterno}
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Correo
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({ ...usuario, correo: e.target.value })
                          }
                          value={usuario.correo}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Contraseña
                        </label>
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          type="password"
                          className={`form-control`}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="form-control-label"
                          htmlFor="inputDanger1"
                        >
                          Numero telefonico
                        </label>
                        <input
                          onChange={(e) =>
                            setUsuario({
                              ...usuario,
                              numeroTelefonico: e.target.value,
                            })
                          }
                          value={usuario.numeroTelefonico}
                          className={`form-control`}
                        />
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "45px" }}>
                      <div className="col-lg-12">
                        <p>Imagen</p>
                        <DropZone
                          saveFile={async (file) => {
                            setFile(file);
                            var reader = new FileReader();
                            var url = reader.readAsDataURL(file);
                          }}
                        />
                      </div>
                    </div>

                    {file ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={URL.createObjectURL(file)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                            <Typography
                              variant="body1"
                              component="span"
                              style={{
                                width: "-webkit-fill-available",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {!file && usuario.imagen ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={String(imagen)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* Botones */}
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-lg-6">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate("/usuarios")}
                        >
                          Cancelar
                        </Button>
                      </div>
                      <div className="col-lg-6">
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => actualizarUsuario()}
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MiCuenta;
